<template>
    <section class="section__body">

        <!--- Navigation --->
        <FuelList_navigation />

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
            FuelList
        </section>
    </section>
</template>

<script>
    import FuelList_navigation from "./FuelList_navigation.vue";
    import { mapGetters } from "vuex";

    export default {
        name: "FuelList",
        components: {
            FuelList_navigation,
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsCount",
            ])
        },
    }
</script>

<style scoped>

</style>